import React, { useState } from "react";
import styled from "styled-components";
import { AuthContentContainer } from "../../../components/styled/AuthContentContainer";
import TextField from "@mui/material/TextField";
import FormButton from "../../../components//buttons/FormButton";
import { sendPasswordResetEmail } from "firebase/auth";
import { isNil } from "lodash";
import { auth } from "../../../store/firebase";

const FormContainer = styled.div``;

const Heading = styled.div`
  font-size: 16px;
  text-align: center;
`;

const ForgotPassword = ({ setForgotPassword }) => {
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const isDisabled = () => {
    if (email === "" || isNil(email)) {
      return true;
    } else {
      return false;
    }
  };

  const handleEnter = async () => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setLoading(false);
      setSent(true);
    } catch (error) {
      console.log("what is the error of sending email", error);
      setLoading(false);
      setSent(false);
    }
  };

  const handleReturnToLogin = () => {
    setForgotPassword(false);
  };

  return (
    <FormContainer>
      {!sent && (
        <>
          <Heading>
            Forgot your password? No problem. Enter your email and we will send
            you an email where you can reset your password.
          </Heading>
          <AuthContentContainer>
            <TextField
              fullWidth
              id="email"
              label="Email"
              placeholder="johndoe@mail.com"
              inputProps={{
                style: { fontSize: 14, height: 14 },
              }}
              InputLabelProps={{ shrink: true }}
              onChange={handleEmailChange}
            />
          </AuthContentContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <FormButton
              handleClick={handleEnter}
              isloading={loading}
              content={"Send Password Reset Email"}
              width={"234px"}
              height={"28px"}
              disabled={isDisabled()}
            />
          </div>
        </>
      )}

      {sent && (
        <div>
          <Heading>Password reset email has been sent!</Heading>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <FormButton
              handleClick={handleReturnToLogin}
              isloading={loading}
              content={"Return to log in"}
              width={"184px"}
              height={"28px"}
            />
          </div>
        </div>
      )}
    </FormContainer>
  );
};

export default ForgotPassword;
